import React, { useState } from "react";

import { Modal, Button, Col, Row, Form } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import API from "../../common/API";
import { parsedUser } from "../../common/GetCurrentUser";
import { useEffect } from "react";

//Translation
import { useTranslation } from "react-i18next";

//Component
const AddEditNotification = (props) => {
  const { t } = useTranslation("global");
  const [types, setTypes] = useState([]);
  const [state, setState] = useState({
    deviceType: [],
    UI: {
      isLoading: false,
    },
  });

  useEffect(() => {
    requestTypes();
  }, []);

  //Validation
  const validationSchema = yup.object({
    type: yup.string().required(t("message.warning.field_required")),
  
  });

  const requestTypes = async () => {
    try {
      let requestData = await API.getAction("notifications/types");

      if (requestData.data.status === "ok") {

      setTypes(JSON.parse(requestData.data.response));
      }
    } catch (error) {
      console.error("THATS A ERROR!!!", error);
    }
  };

  const handleSubmit = async (obj) => {
    try {
      console.log("values to submit", obj);
      let path = "";
      let user = parsedUser();

      if (obj.id.length === 0) {
        path = "notifications/Add?userId=" + user.id;
      } else {
        path = "notifications/Update?userId=" + user.id + "&id=" + obj.id;
      }

      let params = {
        id: obj.id,
        type: obj.type,
        identifier: obj.identifier,
        phone: obj.phone,
      };

      setState({
        ...state,
        UI: { ...state, isLoading: !state.UI.isLoading },
      });

      let request = await API.postAction(path, params);

      if (request.status === 200) {
        console.log("Request Data 200", request.data);
        props.toggle("null", true);
      }
    } catch (ex) {
      console.error(ex);
      setState({
        ...state,
        UI: { ...state, isLoading: !state.UI.isLoading },
      });
    }
    finally{
      setState({
        ...state,
        UI: { ...state, isLoading: false },
      });
    }
  };

  const formik = useFormik({
    initialValues: props.model
      ? JSON.parse(JSON.stringify(props.model))
      : {
          id: "",
          type: "",
          always:true,
          web: true,
          mail: true,
          sms: true
        },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Modal show={props.show} onHide={props.toggle} size="md">
      <Modal.Header closeButton>
        <Modal.Title>{t("notification")}</Modal.Title>
      </Modal.Header>

      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Row>

            <Col xs={6}>
              <Form.Label>{t("type")}</Form.Label>
              <Form.Select
                name="type"
                value={ formik.values.type
                }
                onChange={formik.handleChange}
              >
             
                {types.map((item, index) => {
              
                  return (
                    <option key={index} value={item.type}>
                      {item.type}
                    </option>
                  );
                })}
              </Form.Select>
              {formik.errors.type ? (
                <div className="invalid text-sm">
                  {formik.errors.type}
                </div>
              ) : null}
            </Col>

           
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            type="submit"
            className="btn-sm"
            disabled={state.UI.isLoading}
          >
            {t("generic.words.singular.save")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddEditNotification;
