import { Card, Row, Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import API from "../../common/API";
import Pagination from "../Common/Pagination";
import AddEditUser from "./AddEditUser";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Routes } from "../../routes";
import { useHistory } from "react-router-dom";

//Translation
import { useTranslation } from "react-i18next";

const SubAccountsList = (props) => {
  const { t } = useTranslation("global");
  const [state, setState] = useState({
    records: [],
    totalPages: 0,
    totalRecords: 0,
    currentPage: 0,
    recordsPerPage: 50,
    From: null,
    Until: null,
    UI: {
      showAddEdit: false,
    },
  });

  const history = useHistory();

  useEffect(() => {
    request();
  }, []);

  const request = async () => {
    let query = "id=" + props.id;
    query += "Page=" + state.currentPage;
    query += "&Quantity=" + state.recordsPerPage;

    var request = await API.getAction("Account/SubAccounts", query);
    console.log("Response from api", request);

    if (request.data.status === "ok") {
      setState({
        ...state,
        records: request.data.response,
        totalRecords: request.data.quantityOfRecords,
        totalPages: request.data.quantityOfPages,
      });
    }
  };

  const onPageChanged = async (obj) => {
    setState({ ...state, currentPage: obj });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const [showAddEdit, setShowAddEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const toggle = async (obj, reload) => {
    setSelectedItem(obj);
    setShowAddEdit(!showAddEdit);
    if (reload) {
      request();
    }
  };
  const deleteConfirm = async (obj) => {
    confirmAlert({
      title: t("generic.words.singular.confirm"),
      message: t("message.warning.sure_delete") + obj.firstName + "?",
      buttons: [
        {
          label: t("generic.words.singular.yes"),
          onClick: async () => {
            await API.getAction("Account/Delete?id=" + obj.id);
            await request();
          },
        },
        {
          label: t("generic.words.singular.no"),
        },
      ],
    });
  };

  const toggleShowDetails = (obj) => {
    history.push(Routes.UserDetails.clean + obj.id);
  };

  return (
    <>
      {showAddEdit ? (
        <AddEditUser toggle={toggle} show={showAddEdit} model={selectedItem} />
      ) : null}
      <Row>
        <Col xs={12}>
          <Card className="shadow-sm rounded-0">
            <Card.Header className="mx-0 p-1">
              <Row>
                <Col className="mt-3 ms-2">
                  <Card.Title>
                    {t("generic.words.plural.subaccounts")}
                  </Card.Title>
                </Col>
                <Col className="mt-3 me-3 text-right">
                  <Row>
                    <Col>
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => toggle()}
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="table-responsive p-1">
              {state.records.length > 0 ? (
                <table className="table table-stripped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{t("generic.words.singular.name")}</th>
                      <th>{t("generic.words.singular.email")}</th>

                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.records.map((item, index) => {
                      let itemParsed = JSON.parse(JSON.stringify(item));

                      return (
                        <tr key={index}>
                          <td>
                            {state.currentPage === 0
                              ? index + 1
                              : (index + 1) * (state.currentPage + 1)}
                          </td>
                          <td
                            className="shadow-sm clickable mb-2"
                            key={index}
                            onClick={() => {
                              toggleShowDetails(itemParsed);
                            }}
                          >
                            {item.firstName} {item.lastName}
                          </td>
                          <td>{item.email}</td>
                          <td className="text-right">
                            <div className="btn-group">
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => toggle(itemParsed)}
                              >
                                <i className="fa fa-edit" />
                              </button>
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => deleteConfirm(itemParsed)}
                              >
                                <i className="fa fa-trash text-danger" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <p className="text-center my-4">
                  {t("message.info.is_no_content")}
                </p>
              )}
            </Card.Body>
            <Card.Footer className="p-1">
              <Pagination
                totalPages={state.totalPages}
                currentPage={state.currentPage}
                onPageChanged={onPageChanged}
              />
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SubAccountsList;
