//Dictionaries

//spanish
import global_es_DO from "../dictionaries/es-DO/global.json";

//english
import global_en_US from "../dictionaries/en-US/global.json";

//Here you need add all languages
export const languages = [
  { name: "es-DO", code: "es_DO" },
  { name: "en-US", code: "en_US" },
];

//Here you need add all dictionaries
export const resources = {
  es_DO: { global: global_es_DO },
  en_US: { global: global_en_US },
};
