import { Card, Row, Col, Tabs, Container } from "react-bootstrap";
import moment from "moment";
import React, { useEffect, useState } from "react";
import API from "../../common/API";
import Settings from "../../common/Settings";
import { Routes } from "../../routes";
import PinComponent from "../Common/PinComponent";
import GoogleMapReact from "google-map-react";
import { parsedUser } from "../../common/GetCurrentUser";
import { Tab } from "bootstrap";
import Stops from "../Reports/Stops";
import Trips from "../Reports/Trips";
import SMSSender from "./SMSSender";

import { useParams } from "react-router-dom";
import MapHelper from "../../common/MapHelper";
import { useHistory } from "react-router-dom";

//Translation
import { useTranslation } from "react-i18next";
import { confirmAlert } from "react-confirm-alert";
import AddEditDevice from "./AddEditDevice";

const DeviceDetails = (props) => {
  const { t } = useTranslation("global");
  const history = useHistory();
  //const [intervalId, setIntervalId] = useState(null);
  const [model, setModel] = useState(null);
  const [commands, setCommands] = useState({ records: [] });
  const user = parsedUser();
  const [pins, setPins] = useState([]);
  const { id } = useParams();
  const [showTrips, setShowTrips] = useState(false);
  const [showStop, setShowStop] = useState(false);
  const [showSMS, setShowSMS] = useState(false);
  const [SMSModel, setSMSModel] = useState(null);
  const GoogleMapRef = React.createRef();
  const [showAddEdit, setShowAddEdit] = useState(false);
  const [theMap, setTheMap] = useState(null);
  const [theMapsReference, setTheMapsReference] = useState(null);

  const [state, setState] = useState({
    center: { lat: 18.4718609, lng: -69.8923187 },
    zoom: 18,
    scale: {
      sm: 1,
      md: 2.5,
      lg: 3.5,
      xl: 4.5,
      xxl: 6,
    },
    DeviceSelected: null,
    Events: [],
    UI: {
      showAddEditGeofence: false,
      GeofenceSelected: null,
    },
  });

  const handleApiLoaded = (map, mapsReference) => {
    setTheMap(map);
    setTheMapsReference(mapsReference);
  };

  //Device related
  const requestDevice = async () => {
    try {
      let request = await API.getAction(`device/details?id=${id}`, null, null);

      if (request.data.status === "ok") {
        let dev = request.data.response;
        console.log(dev);
        setModel(dev);
        setPins([dev.position]);
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  const requestCommands = async () => {
    try {
      let request = await API.getAction(
        "CommandsAvailable/list?deviceTypeId=" + model.device.deviceTypeId
      );

      if (request.data.status === "ok") {
        setCommands({ commands, records: request.data.response });
      }
    } catch (error) {}
  };

  const share = () => {
    if (model.latitude && model.longitude) {
      let title = t("generic.words.singular.model") + " " + props.model.name;
      var GoogleMapDirectionURL = `https://www.google.com/maps/dir/?api=1&destination=${state.DeviceSelected.latitude},${state.DeviceSelected.longitude}`;
      navigator.share({
        title: title,
        text: title,
        url: GoogleMapDirectionURL,
      });
    } else {
      alert("No hay suficiente información para compartir la ubicación. . .");
    }
  };

  const directions = () => {
    if (model.latitude && model.longitude) {
      let url = `https://www.google.com/maps/dir/?api=1&destination=${state.DeviceSelected.latitude},${state.DeviceSelected.longitude}`;

      window.open(url, "_blank");
    } else {
      console.log(model);
      alert("No hay suficiente información para poder trazar una ruta. . .");
    }
  };

  useEffect(() => {
    if (model && model.position) {
      MapHelper.centerMap(
        theMapsReference,
        theMap,
        [model.position],
        state.zoom
      );
    }
  }, [theMap, theMapsReference, model]);

  useEffect(() => {
    requestDevice();
  }, [id]);

  useEffect(() => {
    requestCommands();
  }, [model]);

  useEffect(() => {
    let intervalId = null;

    const onFocus = () => {
      console.log("CON FOCO");
      intervalId = setInterval(() => {
        requestDevice();
      }, 5000);
    };
    const onBlur = () => {
      console.log("SIN FOCO");
      clearInterval(intervalId);
    };

    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);

    if (intervalId === null) {
      onFocus();
    }

    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
      clearInterval(intervalId);
    };
  }, []);

  const deleteConfirm = async () => {
    let user = parsedUser();

    confirmAlert({
      title: t("generic.words.singular.confirm"),
      message: t("message.warning.sure_delete") + model.device.name + " ?",
      buttons: [
        {
          label: t("generic.words.singular.yes"),
          onClick: async () => {
            await API.postAction(
              "Device/Delete?userId=" + user.id + "&id=" + id
            );

            history.push(Routes.Devices.path);
          },
        },
        {
          label: t("generic.words.singular.no"),
        },
      ],
    });
  };
  const toggleTrips = () => {
    setShowTrips(!showTrips);
  };

  const toggleShowStop = () => {
    setShowStop(!showStop);
  };

  const toggleSMS = (obj) => {
    setShowSMS(!showSMS);
    setSMSModel(obj);
  };

  const toggleAddEdit = async (obj, reload) => {
    setShowAddEdit(!showAddEdit);
    if (reload) {
      requestDevice();
    }
  };

  const onHandleZoom = (obj) => {
    setState({ ...state, zoom: obj.zoom });
  };

  if (model === null || !model.device) {
    return <></>;
  }

  return (
    <>
      {showStop ? (
        <Stops model={model} show={showStop} toggle={toggleShowStop} />
      ) : null}
      {showTrips ? (
        <Trips model={model} show={showTrips} toggle={toggleTrips} />
      ) : null}
      {showSMS ? (
        <SMSSender model={SMSModel} show={showSMS} toggle={toggleSMS} />
      ) : null}

      {showAddEdit ? (
        <AddEditDevice
          toggle={toggleAddEdit}
          show={showAddEdit}
          model={model.device}
        />
      ) : null}

      <Container>
        <nav aria-label="breadcrumb" className="mb-2">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">
                <i className="fa fa-home"></i>
              </a>
            </li>

            <li className="breadcrumb-item">
              <a href={Routes.Devices.path}>
                {t("generic.words.plural.devices")}
              </a>
            </li>
            <li className="breadcrumb-item">
              {t("generic.words.singular.device")}
            </li>
          </ol>
        </nav>
        <Row>
          <Col md={12} lg={4}>
            <Card className="shadow-sm mb-4">
              <Card.Header>
                <h3>{model.device.name}</h3>
              </Card.Header>
              <Card.Body>
                <Col xs={6} className="mb-2">
                  <b>{t("generic.words.singular.model")}: </b>
                  {model.device?.deviceType.name}
                </Col>
                <Col xs={6} className="mb-2">
                  <b>{t("generic.words.singular.phone")}: </b>
                  {model.device?.phone}
                </Col>
                <Col xs={6} className="mb-2">
                  <b>{t("generic.words.singular.imei")}: </b>
                  {model.device?.identifier}
                </Col>
                <Col xs={6} className="mb-2">
                  <b>{t("generic.sentences.last_update")}: </b>{" "}
                  {model.serviceInfo
                    ? moment(model.serviceInfo.lastUpdate).fromNow()
                    : "--"}
                </Col>
                <Col xs={6} className="mb-2">
                  <b>{t("generic.words.singular.battery")}: </b>
                  <br />
                  {model.position && model.position.attributes.batteryLevel ? (
                    model.position.attributes.batteryLevel >= 80 ? (
                      <div className="badge bg-success badge-sm">
                        {model.position.attributes.batteryLevel}%
                      </div>
                    ) : model.position.attributes.batteryLevel >= 40 ? (
                      <div className="badge bg-warning badge-sm">
                        {model.position.attributes.batteryLevel}%
                      </div>
                    ) : (
                      <div className="badge bg-danger badge-sm">
                        {model.position.attributes.batteryLevel}%
                      </div>
                    )
                  ) : (
                    "--"
                  )}
                </Col>
              </Card.Body>
              <Card.Footer>
                <Row>
                  <Col
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="d-flex justify-content-center mt-2"
                  >
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => {
                        console.log(model.device.deviceType.name);
                        directions();
                      }}
                    >
                      <i className="fas fa-directions"></i>{" "}
                      {t("generic.words.plural.indications")}
                    </button>
                  </Col>
                  <Col
                    sm={12}
                    md={6}
                    lg={6}
                    xl={6}
                    className="d-flex justify-content-center mt-2"
                  >
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => {
                        share();
                      }}
                    >
                      <i className="fas fa-share-square"></i>{" "}
                      {t("generic.words.singular.share")}
                    </button>
                  </Col>

                  <Col
                    sm={12}
                    md={6}
                    lg={12}
                    xl={6}
                    className="d-flex justify-content-center mt-2"
                  >
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => {
                        toggleAddEdit();
                      }}
                    >
                      <i className="fas fa-edit"></i>{" "}
                      {t("generic.words.singular.edit")}
                    </button>
                  </Col>
                  <Col
                    sm={12}
                    md={6}
                    lg={12}
                    xl={6}
                    className="d-flex justify-content-center mt-2"
                  >
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => deleteConfirm()}
                    >
                      <i className="fa fa-trash text-danger" />{" "}
                      {t("generic.words.singular.delete")}
                    </button>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
          <Col md={12} lg={8}>
            <Card className="shadow-sm mb-4">
              <Card.Body>
                <GoogleMapReact
                  ref={GoogleMapRef}
                  yesIWantToUseGoogleMapApiInternals={true}
                  onGoogleApiLoaded={({ map, maps }) =>
                    handleApiLoaded(map, maps)
                  }
                  bootstrapURLKeys={{ key: Settings.GoogleMapsAPIKey }}
                  style={{
                    width: "100%",
                    minHeight: "300px",
                    height: "68vh",
                    position: "relative",
                  }}
                  defaultCenter={{
                    lat: state.center.lat,
                    lng: state.center.lng,
                  }}
                  defaultZoom={18}
                  onChange={(e) => onHandleZoom(e)}
                >
                  {console.log(pins)}
                  {pins[0] &&
                    pins.map((item, index) => {
                      return (
                        <PinComponent
                          key={index}
                          model={item}
                          lat={item.latitude}
                          lng={item.longitude}
                          zoom={state.zoom}
                          scale={state.scale}
                          title={model.device?.name}
                        />
                      );
                    })}
                </GoogleMapReact>
              </Card.Body>
            </Card>
            <Card className="shadow-sm mb-4">
              <Card.Body>
                <Tabs
                  defaultActiveKey="reports"
                  id="justify-tab-example"
                  className=" containerTab"
                  justify
                  fill
                >
                  <Tab
                    eventKey="reports"
                    title={t("generic.words.plural.reports")}
                  >
                    <button
                      className="btn btn-block"
                      onClick={() => {
                        toggleShowStop();
                      }}
                    >
                      {t("generic.sentences.shutdown_report")}
                    </button>
                    <button
                      className="btn btn-block"
                      onClick={() => {
                        toggleTrips();
                      }}
                    >
                      {t("generic.sentences.travels_report")}
                    </button>
                    {/* <button className='btn btn-block' onClick={() => toggleShowStop()}>Reporte de Eventos</button>
                      <button className='btn btn-block' onClick={() => toggleShowStop()}>Reporte Historico</button> */}
                  </Tab>
                  <Tab eventKey="codes" title="Enviar Código">
                    {commands.records.length > 0 ? (
                      <>
                        {commands.records.map((item, index) => {
                          return (
                            <button
                              key={index}
                              className="btn btn-block"
                              onClick={() => {
                                toggleSMS({
                                  Title: item.tilte,
                                  SMS: `sms://${model.device.phone};?&body=${item.command}`,
                                  Note: item.descriptionalLabel.replace(
                                    "{NUMBER}",
                                    model.device.phone
                                  ),
                                });
                              }}
                            >
                              {item.title}
                            </button>
                          );
                        })}
                      </>
                    ) : (
                      <p className="text-center my-4">
                        {t("message.info.is_no_content")}
                      </p>
                    )}
                  </Tab>
                </Tabs>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DeviceDetails;
