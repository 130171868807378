import React, { useState } from "react";

import { Modal, Button, Col, Row, Form } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import API from "../../common/API";
import { parsedUser } from "../../common/GetCurrentUser";
import { useEffect } from "react";

//Translation
import { useTranslation } from "react-i18next";

//Component
const CommandMaintenanceAddEdit = (props) => {
  const { t } = useTranslation("global");

  const [state, setState] = useState({
    deviceType: [],
    UI: {
      isLoading: false,
    },
  });

  useEffect(() => {
    request();
    console.log(props);
  }, []);

  //Validation
  const validationSchema = yup.object({
    title: yup.string().required(t("message.warning.field_required")),
    description: yup.string().required(t("message.warning.field_required")),
    command: yup.string().required(t("message.warning.field_required")),
    descriptionalLabel: yup
      .string()
      .required(t("message.warning.field_required")),
  });

  const request = async () => {
    try {
      let requestData = ""; /* await API.getAction("DeviceType/List"); */

      console.log("Data traida", requestData);

      if (requestData.data.status === "ok") {
        setState({ ...state, deviceType: requestData.data.response });
      }
    } catch (error) {
      console.error("THATS A ERROR!!!", error);
    }
  };

  const handleSubmit = async (obj) => {
    try {
      console.log("values to submit", obj);
      let user = parsedUser();
      let path = "";
      if (obj.id.length === 0) {
        path = "CommandsAvailable/Add?userId=" + user.id;
      } else {
        path = "CommandsAvailable/Update?userId=" + user.id + "&id=" + obj.id;
      }

      let params = {
        title: obj.title,
        description: obj.description,
        descriptionalLabel: obj.descriptionalLabel,
        command: obj.command,
        deviceTypeId: props.deviceTypeId,
      };
      console.log(path);
      console.log(params);
      setState({
        ...state,
        UI: { ...state, isLoading: !state.UI.isLoading },
      });

      let request = await API.postAction(path, params);

      if (request.status === 200) {
        console.log("Request Data 200", request.data);
        props.toggle("null", true);
      }
    } catch (ex) {
      console.error(ex);
      setState({
        ...state,
        UI: { ...state, isLoading: !state.UI.isLoading },
      });
    }
  };

  const formik = useFormik({
    initialValues: props.model
      ? JSON.parse(JSON.stringify(props.model))
      : {
          title: "",
          description: "",
          descriptionalLabel: "",
          command: "",
          deviceTypeId: "",
        },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Modal show={props.show} onHide={props.toggle} size="md">
      <Modal.Header closeButton>
        <Modal.Title>{t("generic.words.singular.command")}</Modal.Title>
      </Modal.Header>

      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Row>
            <Col xs={6}>
              <Form.Label>{t("generic.words.singular.title")}</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
              />

              {formik.errors.title ? (
                <div className="invalid text-sm">{formik.errors.title}</div>
              ) : null}
            </Col>
            <Col xs={6}>
              <Form.Label>{t("generic.words.singular.command")}</Form.Label>
              <Form.Control
                type="text"
                name="command"
                value={formik.values.command}
                onChange={formik.handleChange}
              />

              {formik.errors.command ? (
                <div className="invalid text-sm">{formik.errors.command}</div>
              ) : null}
            </Col>
            <Col xs={12}>
              <Form.Label>
                {t("generic.sentences.descriptional_label")}
              </Form.Label>
              <Form.Control
                type="text"
                name="descriptionalLabel"
                value={formik.values.descriptionalLabel}
                onChange={formik.handleChange}
              />

              {formik.errors.descriptionalLabel ? (
                <div className="invalid text-sm">
                  {formik.errors.descriptionalLabel}
                </div>
              ) : null}
            </Col>
            <Col xs={12}>
              <Form.Label>{t("generic.words.singular.description")}</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
              ></Form.Control>
              {formik.errors.description ? (
                <div className="invalid text-sm">
                  {formik.errors.description}
                </div>
              ) : null}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            type="submit"
            className="btn-sm"
            disabled={state.UI.isLoading}
          >
            {t("generic.words.singular.save")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CommandMaintenanceAddEdit;
