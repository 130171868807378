import React, { useEffect, useState } from "react";

import { Col, Row, Card, Container } from "react-bootstrap";

import API from "../../common/API";

//Translation
import { useTranslation } from "react-i18next";

const DashboardOverview = () => {
  const { t } = useTranslation("global");
  const [summary, setSummary] = useState([]);
  const request = async () => {
    try {
      let requestApi = await API.getAction("dashboard/summary");

      if (requestApi.data.status === "ok") {
        setSummary(requestApi.data.response);
      }
      console.log("requestAPI", requestApi);
    } catch (error) {}
  };

  useEffect(() => {
    request();
  }, []);

  return (
    <>
      <Container className="mt-2">
        <Row className="text-center ">
          <Col md={6} lg={4} className="mt-2">
            <Card className="shadow">
              <Card.Header>
                <Card.Title className="display-4">
                  {t("generic.words.plural.users")}
                </Card.Title>
              </Card.Header>
              <Card.Body className="">
                <h2>{summary.user}</h2>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6} lg={4} className="mt-2">
            <Card className="shadow">
              <Card.Header>
                <Card.Title className="display-4">
                  {t("generic.words.plural.devices")}
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <h2>{summary.devices}</h2>
              </Card.Body>
            </Card>
          </Col>
          <Col md={12} lg={4} className="mt-2">
            <Card className="shadow">
              <Card.Header>
                <Card.Title className="display-4">
                  {t("generic.sentences.device_type")}
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <h2>{summary.deviceTypes}</h2>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DashboardOverview;
