import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "./routes";
import { SocketContext, TraccarSocket } from "./Context/TraccarSocket";

// pages
import Home from "./pages/home";

import Users from "./pages/users";

import Profile from "./pages/profile";
import Login from "./pages/login";
import Register from "./pages/register";
// import ForgotPassword from "./pages/ForgotPassword";

import Subscriptions from "./pages/subscriptions";

import Settings from "./pages/settings";
// import geofences

import Devices from "./pages/devices";
import DeviceType from "./pages/deviceType";

// components
import Sidebar from "./components/Common/Sidebar";
import Navbar from "./components/Common/Navbar";
import Preloader from "./components/Preloader";
import IsLogedIn from "./common/IsLogedIn";
import UserDetails from "./components/Users/UserDetails";
import DeviceTypeDetails from "./components/DevicesType/DeviceTypeDetails";

import DeviceDetails from "./components/Devices/DeviceDetails";

import PermissionNotification from "./components/Settings/PermissionNotification";
import NotificationList from "./components/Notifications/NotificationList";

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  const history = useHistory();

  /*const warningNotification = () =>{
    addNotification({
      title:'Solicitud de Permiso',
      message: '¿Desea recibir notificaciones de esta web?',
      closeButton: 'x',
      native: true,
      duration: 5000
    })
  }*/

  useEffect(() => {
    if (!IsLogedIn()) {
      history.push(Routes.SignIn.path);
    }

    const timer = setTimeout(() => setLoaded(true), 1000);

    //warningNotification();
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem("settingsVisible") === "false" ? false : true;
  };

  const [showSettings, setShowSettings] = useState(
    localStorageIsSettingsVisible
  );

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem("settingsVisible", !showSettings);
  };

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Preloader show={loaded ? false : true} />

          <Sidebar />

          <main className="content p-0" style={{ overflow: "hidden" }}>
            <Navbar {...props} />

            <Component {...props} />

            {/* <PermissionNotification
              show={
                "Notification" in window &&
                Notification.permission === "default"
                  ? true
                  : false
              }
            /> */}
          </main>
        </>
      )}
    />
  );
};

export default () => {
  const socket = TraccarSocket(() => {
    // let theUser = parseJwt(GetCurrentUser());
    // if (theUser) {
    //   socket.invoke("Online", theUser.ID)
    // }
  });

  return (
    <SocketContext.Provider value={socket}>
      <Switch>
        {/* pages */}
        <RouteWithSidebar exact path={Routes.Home.path} component={Home} />
        <RouteWithSidebar
          exact
          path={Routes.Settings.path}
          component={Settings}
        />
        <RouteWithSidebar
          exact
          accountId={0}
          path={Routes.Devices.path}
          component={Devices}
        />

        <RouteWithSidebar
          exact
          path={Routes.DeviceDetails.path}
          component={DeviceDetails}
        />

        <RouteWithSidebar exact path={Routes.Users.path} component={Users} />

        <RouteWithSidebar
          exact
          path={Routes.UserDetails.path}
          component={UserDetails}
        />

        {/* <RouteWithSidebar
          exact
          path={Routes.Geofences.path}
          component={Geofences}
        /> */}

        <RouteWithSidebar
          exact
          path={Routes.Profile.path}
          component={Profile}
        />

        <RouteWithSidebar
          exact
          path={Routes.DeviceType.path}
          component={DeviceType}
        />

        <RouteWithSidebar
          exact
          path={Routes.DeviceTypeDetails.path}
          component={DeviceTypeDetails}
        />
        <RouteWithSidebar
          exact
          path={Routes.Notifications.path}
          component={NotificationList}
        />

        <RouteWithSidebar
          exact
          path={Routes.Subscriptions.path}
          component={Subscriptions}
        />
        <Route path={Routes.SignIn.path} component={Login} />
        <Route path={Routes.Register.path} component={Register} />
        {/* <Route path={Routes.ForgotPassword.path} component={ForgotPassword} /> */}

        <Redirect to={Routes.NotFound.path} />
      </Switch>
    </SocketContext.Provider>
  );
};
