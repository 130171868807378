export const Routes = {
  // pages
  Home: { path: "/" },
  Settings: { path: "/Settings" },

  Devices: { path: "/Devices" },
  DeviceDetails: { path: "/Devices/details/:id", clean: "/Devices/details/" },
  DeviceType: { path: "/DeviceType" },

  Notifications: { path: "/Notifications" },
  Subscriptions: { path: "/subscriptions" },
  DeviceTypeDetails: {
    path: "/DeviceType/details/:id",
    clean: "/DeviceType/details/",
  },

  Geofences: { path: "/Geofences" },

  Users: { path: "/Users" },
  UserDetails: { path: "/User/Details/:id", clean: "/User/Details/" },
  ProjectDetails: { path: "/Projects/detail/:id", clean: "/Projects/Detail/" },

  Subscriptions: { path: "/Subscriptions" },

  NotFound: { path: "/Examples/NotFound" },
  ServerError: { path: "/Examples/ServerError" },

  Profile: { path: "/Profile" },
  SignIn: { path: "/Signin" },
  Register: { path: "/Register" },
  ForgotPassword: { path: "/ForgotPassword" },
  ResetPassword: { path: "/ResetPassword" },
};
