import React, { createContext, useEffect } from 'react';

import API from '../common/API';
import qs from 'qs';

export const TraccarSocket = ExecuteOnStart => {

  let socket = new WebSocket(`wss://tracker.lstracking.com/api/socket`);


// Listen for possible errors
socket.addEventListener('error', (event) => {
  console.log('WebSocket error: ', event);
});
  const generatesession = async () => {
    //First the session 
    let headersTraccar = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    };
    let query = qs.stringify({ 'email': 'info@valegos.com', 'password': 'Valego2015@' });
    // let request = await API.getActionExternal(process.env.TRACCAR_SERVER + "/api/server", {}, headersTraccar);
    let session = await API.postActionExternal(`https://tracker.lstracking.com/api/session`, query, headersTraccar);
  }
  const start = () => {

    socket.onopen = () => {
      console.log("on open");
    };


    socket.onclose = function (e) {
      console.log('Socket is closed. Reconnect will be attempted in 5 second.', e.reason);
      setTimeout(function () {
        start();
      }, 5000);
    };

    socket.onerror = function (err) {
      console.error('Socket encountered error: ', err, err.message, 'Closing socket');
      socket.close();
    };
  };


  generatesession();
  start();

  return socket;
}

export const SocketContext = createContext();