import { Card, Row, Col } from "react-bootstrap";
import moment from "moment";
import Pagination from "../Common/Pagination";
import React, { useEffect, useState } from "react";
import API from "../../common/API";
import { parsedUser } from "../../common/GetCurrentUser";
import IsLogedIn from "../../common/IsLogedIn";
import AddEditNotification from "./AddEditNotification";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { Routes } from "../../routes";

//Translation
import { useTranslation } from "react-i18next";

const NotificationList = (props) => {
  const history = useHistory();
  const { t } = useTranslation("global");
  const [records, setRecords] = useState([]);
  const [notifications, setnotifications] = useState({
    totalPages: 0,
    totalRecords: 0,
    currentPage: 0,
    recordsPerPage: 50,
    From: null,
    Until: null,
  });

  const [showAddEdit, setShowAddEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [search, setSearch] = useState("");

  //notification related
  const request = async () => {
    let user = parsedUser();
    let id = props.accountId === undefined ? user.id : props.accountId;
    let query = "id=" + id + "&search=" + search;
    console.log(query);
    let request = await API.getAction("notifications/list?" + query);
    console.log("Response notifications request", request);
    if (request.data.status === "ok") {
      setRecords(JSON.parse(request.data.response));
    }
  };

  useEffect(() => {
    if (IsLogedIn()) {
      request();
    }
  }, [search]);

  const toggleShowAddEdit = async (obj, reload) => {
    setSelectedItem(obj);
    setShowAddEdit(!showAddEdit);
    if (reload) {
      request();
    }
  };

  const toggleShowDetails = (obj) => {
    history.push(Routes.notificationDetails.clean + obj.id);
  };

  const onPageChanged = async (obj) => {
    setnotifications({ ...notifications, currentPage: obj });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const deleteConfirm = async (obj) => {
    let user = parsedUser();

    confirmAlert({
      title: t("generic.words.singular.confirm"),
      message: t("message.warning.sure_delete") + obj.name + "?",
      buttons: [
        {
          label: t("generic.words.singular.yes"),
          onClick: async () => {
            await API.postAction(
              "notifications/Delete?userId=" + user.id + "&id=" + obj.id
            );
            await request();
          },
        },
        {
          label: t("generic.words.singular.no"),
        },
      ],
    });
  };
  return (
    <>
      {showAddEdit ? (
        <AddEditNotification
          toggle={toggleShowAddEdit}
          show={showAddEdit}
          model={selectedItem}
        />
      ) : null}

      <Row>
        <Col xs={12}>
          <Card
            className={
              props.accountId === undefined
                ? "shadow-sm m-3 p-2"
                : "shadow-sm rounded-0"
            }
          >
            <Card.Header className="mx-0 p-1">
              <Row>
                <Col className="mt-2 ms-2">
                  <Card.Title>{t("Notifications")}</Card.Title>
                </Col>
                <Col xs={4} className="ml-auto text-right">
                  <div className="input-group">
                    <div className="input-group-text">
                      <i className="fa fa-search"></i>
                    </div>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder={t("generic.words.singular.filter")}
                      value={search}
                      onChange={async (e) => {
                        setSearch(e.target.value);
                      }}
                    />

                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => toggleShowAddEdit()}
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                  </div>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="table-responsive p-1">
              {records.length > 0 ? (
                <table className="table table-stripped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {records.map((notification, index) => {
                      let itemParsed = JSON.parse(JSON.stringify(notification));
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{notification.type}</td>
                          <td className="text-right">
                            <div className="btn-group">
                              {/* <button
                                className="btn btn-primary btn-sm"
                                onClick={() => {
                                  toggleShowAddEdit(itemParsed);
                                }}
                              >
                                <i className="fa fa-edit" />
                              </button> */}
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => deleteConfirm(itemParsed)}
                              >
                                <i className="fa fa-trash text-danger" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <p className="text-center my-4">
                  {t("message.info.is_no_content")}
                </p>
              )}
            </Card.Body>
            <Card.Footer>
              <Pagination
                totalPages={notifications.totalPages}
                currentPage={notifications.currentPage}
                onPageChanged={onPageChanged}
              />
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default NotificationList;
