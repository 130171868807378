import { Card, Row, Col } from "react-bootstrap";
import moment from "moment";
import Pagination from "../Common/Pagination";
import React, { useEffect, useState } from "react";
import API from "../../common/API";
import { parsedUser } from "../../common/GetCurrentUser";
import IsLogedIn from "../../common/IsLogedIn";
import { useHistory, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { Routes } from "../../routes";

//Translation
import { useTranslation } from "react-i18next";
import { commandsAvailable } from "../../common/helpers";
import CommandMaintenanceAddEdit from "./CommandMaintenanceAddEdit";

const CommandMaintenanceList = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation("global");
  const [commands, setCommands] = useState({
    records: [],
    totalPages: 0,
    totalRecords: 0,
    currentPage: 0,
    recordsPerPage: 50,
    From: null,
    Until: null,
  });

  const [showAddEdit, setShowAddEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [search, setSearch] = useState("");

  //Device related
  const requestCommands = async () => {
    let query = "deviceTypeId=" + id + "&search=" + search;

    console.log(query);

    let request = await API.getAction("CommandsAvailable/List?" + query);

    console.log("Response devices request", request);

    if (request.data.status === "ok") {
      setCommands({
        ...commands,
        records: request.data.response,
        totalRecords: request.data.quantityOfRecords,
        totalPages: request.data.quantityOfPages,
      });
    }
  };

  useEffect(() => {
    if (IsLogedIn()) {
      requestCommands();
    }
  }, [search]);

  const toggleShowAddEdit = async (obj, reload) => {
    setSelectedItem(obj);
    setShowAddEdit(!showAddEdit);
    if (reload) {
      requestCommands();
    }
  };

  const toggleShowDetails = (obj) => {
    history.push(Routes.DeviceDetails.clean + obj.id);
  };

  const onPageChanged = async (obj) => {
    setCommands({ ...commands, currentPage: obj });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const deleteConfirm = async (obj) => {
    let user = parsedUser();

    confirmAlert({
      title: t("generic.words.singular.confirm"),
      message: t("message.warning.sure_delete") + obj.title + "?",
      buttons: [
        {
          label: t("generic.words.singular.yes"),
          onClick: async () => {
            await API.postAction(
              "CommandsAvailable/Delete?userId=" + user.id + "&id=" + obj.id
            );
            await requestCommands();
          },
        },
        {
          label: t("generic.words.singular.no"),
        },
      ],
    });
  };
  return (
    <>
      {showAddEdit ? (
        <CommandMaintenanceAddEdit
          toggle={toggleShowAddEdit}
          show={showAddEdit}
          model={selectedItem}
          deviceTypeId={id}
        />
      ) : null}
      <Row>
        <Col xs={12}>
          <Card className="shadow-sm">
            <Card.Header className="mx-0 p-1">
              <Row>
                <Col className="mt-2 ms-2">
                  <Card.Title>
                    {t("generic.sentences.commands_maintenance")}
                  </Card.Title>
                </Col>
                <Col xs={4} className="ml-auto text-right">
                  <div className="input-group">
                    <div className="input-group-text">
                      <i className="fa fa-search"></i>
                    </div>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder={t("generic.words.singular.filter")}
                      value={search}
                      onChange={async (e) => {
                        setSearch(e.target.value);
                      }}
                    />

                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => toggleShowAddEdit()}
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                  </div>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="table-responsive p-1">
              {commands.records.length > 0 ? (
                <table className="table table-stripped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{t("generic.words.singular.title")}</th>
                      <th>{t("generic.words.singular.command")}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {commands.records.map((command, index) => {
                      console.log(command);
                      let itemParsed = JSON.parse(JSON.stringify(command));
                      return (
                        <tr key={index}>
                          <td>
                            {commands.currentPage === 0
                              ? index + 1
                              : (index + 1) * (commands.currentPage + 1)}
                          </td>
                          <td
                            className="shadow-sm clickable mb-2"
                            onClick={() => {
                              console.log(command);
                              toggleShowAddEdit(command);
                            }}
                          >
                            <span>{command.title}</span>
                          </td>
                          <td>
                            <span>{command.command}</span>
                          </td>
                          <td className="text-right">
                            <div className="btn-group">
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => {
                                  toggleShowAddEdit(command);
                                }}
                              >
                                <i className="fa fa-edit" />
                              </button>
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => {
                                  deleteConfirm(command);
                                }}
                              >
                                <i className="fa fa-trash text-danger" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <p className="text-center my-4">
                  {t("message.info.is_no_content")}
                </p>
              )}
            </Card.Body>
            <Card.Footer>
              <Pagination
                totalPages={commands.totalPages}
                currentPage={commands.currentPage}
                onPageChanged={onPageChanged}
              />
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CommandMaintenanceList;
