import React, { useEffect, useState } from 'react';

import { Modal, Col, Row, Form } from 'react-bootstrap';

import * as yup from 'yup';
import { useFormik } from 'formik';

import GoogleMapReact from 'google-map-react';
import moment from 'moment';

import API from '../../common/API';
import { parsedUser } from '../../common/GetCurrentUser';
import Settings from '../../common/Settings';
import PinComponent from '../Common/PinComponent';
import MapHelper from '../../common/MapHelper';


//Validation
const validationSchema = yup.object({
    //    //   id: yup.string().required(),
    //   from: yup.string().required(),
    //    organizationId: yup.string(),

    //to: yup.number().required()


});
//Component
const Stops = props => {

    const user = parsedUser();
    const [state, setState] = useState({
        center: { lat: 18.4718609, lng: -69.8923187 },
        zoom: 15,

        DeviceSelected: null,
        Events: [],
        UI: {
            showAddEditGeofence: false,
            GeofenceSelected: null
        }
    });

    const GoogleMapRef = React.createRef();
    const [results, setResults] = useState([]);

    const handleSubmit = async (obj, event) => {
        console.log("values to submit", obj);

        let query = `reports/stops/${user.id}/${props.model.deviceId}`;
        if (obj.from) {
            query = query + "/" + moment(obj.from).format("YYYY-MM-DDTHH:mm:ss");
        }
        if (obj.to) {
            query = query + "/" + moment(obj.to).format("YYYY-MM-DDTHH:mm:ss");
        }
        let request = await API.getAction(query);


        if (request.data.status === "ok") {

            setResults(request.data.response);

        }
    };
    const formik = useFormik({
        initialValues: {
            from: moment().format(),
            to: moment().format()
        },
        validationSchema,
        onSubmit: handleSubmit,

    });
    const [theMap, setTheMap] = useState(null);
    const [theMapsReference, setTheMapsReference] = useState(null);
    const handleApiLoaded = (map, mapsReference) => {
        setTheMap(map);
        setTheMapsReference(mapsReference);
    }


    useEffect(() => {
        if (theMapsReference) {
            MapHelper.center(theMap, theMapsReference, results);
        }
    }, [results, theMapsReference])


    if (!props.model) {
        return (<></>)
    }
    return (
        <Modal show={props.show} onHide={props.toggle} size='md' className='modalRight'>
            <Modal.Header closeButton>
                <Modal.Title>Paradas</Modal.Title>

            </Modal.Header>
            <Form onSubmit={formik.handleSubmit}>
                <Modal.Body>

                    <Row>
                        <Col xs={5} >
                            <Form.Label>Desde</Form.Label>

                            <input type="datetime-local"
                                name="from"
                                className='form-control'
                                value={formik.values.from ?? ""} onChange={formik.handleChange} />
                            {formik.errors.from ? <div className="invalid text-sm">{formik.errors.from}</div> : null}
                        </Col>
                        <Col xs={5} >
                            <Form.Label>Hasta</Form.Label>
                            <input type="datetime-local"
                                name="to"
                                className='form-control'
                                value={formik.values.to ?? ""} onChange={formik.handleChange} />
                            {formik.errors.to ? <div className="invalid text-sm">{formik.errors.to}</div> : null}
                        </Col>
                        <Col xs={2}>

                            <Form.Label>&nbsp;</Form.Label>
                            <br />
                            <button className='btn btn-sm btn-primary' type="submit">
                                <i className='fa fa-search'></i>
                            </button>
                        </Col>
                        {results.length > 0 ?
                            <Col xs={12}>
                                <GoogleMapReact ref={GoogleMapRef}
                                    
                                    yesIWantToUseGoogleMapApiInternals={true}
                                    onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                                    bootstrapURLKeys={{ key: Settings.GoogleMapsAPIKey }}
                                    style={{ width: "100%", minHeight: "300px", height: "300px", position: "relative" }}
                                    //id={this.props.Element.nombreCampo}
                                    
                                    defaultCenter={{ lat: state.center.lat, lng: state.center.lng }}
                                    //     center={state !== undefined && state.center !== undefined && state.center !== null? state.center.value: null}
                                    defaultZoom={state.zoom}
                                >
                                    {results
                                        .map((item, index) => {
                                            return (<PinComponent model={item}
                                                type="stops"
                                                key={index}
                                                index={index}
                                                lat={item.latitude}
                                                lng={item.longitude}
                                                title={moment(item.duration).fromNow()}
                                            />);
                                        })}
                                </GoogleMapReact>
                                <table className='table table-stripped'>
                                    <thead>
                                        <tr>
                                            <th>Dia</th>
                                            <th>Desde</th>
                                            <th>Hasta</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {results.map((item, index) => {

                                            return (
                                                <tr key={index}>
                                                    <td>

                                                        {moment(item.startTime).format("MMM DD, YYYY")}
                                                    </td>
                                                    <td>
                                                        {moment(item.startTime).format("hh:mm:ss a")}
                                                    </td>
                                                    <td>
                                                        {moment(item.endTime).format("hh:mm:ss a")}
                                                    </td>
                                                    <td>
                                                        <button className='btn btn-sm btn-primary'
                                                            onClick={() => {
                                                                MapHelper.center(theMap, theMapsReference, [item]);
                                                                //let path = `https://www.google.com/maps/dir/?api=1&destination=${item.latitude},${item.longitude}`;
                                                                //window.open(path, "_blank");

                                                            }}
                                                        >
                                                            <i className='fa fa-map mx-2'></i>
                                                            Ver en mapa
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                            </Col>
                            : null}


                    </Row>

                </Modal.Body>

            </Form>

        </Modal>
    )
}

export default Stops;