//We are settings an initial state here for clarity, probably is not needed

const Settings = {
    App: "LS Tracking",
    Name: "lstracking",
    debug : false,
    GoogleMapsAPIKey : "AIzaSyBYmH6z_csu0XTQ8JO5ZK1iquSzk6LrKpU",
    baseURl:"./",
    BasePathForAPI: "https://api.lstracking.com/api/",
    //BasePathForAPI: "http://localhost:5296/api/",
    //BasePathForAPI: "https://app.pleelo.com/api/tracker/",

    EventStatus:[
        {type: "ignitionOn",
        text:   "Dispositivo encendido"},
        {type: "ignitionOff" , text:  "Dispositivo apagado"},
        {type:"geofenceEnter",  text: "Ingreso en la geocerca"},
        {type:"geofenceExit",  text: "Salio de la geocerca"},
        {type:"deviceStopped",  text: "Dispositivo detenido"},
        {type:"deviceMoving",  text: "Dispositivo en movimiento"},
        {type:"deviceOnline",  text: "En Linea"},
        {type:"deviceOffline",  text: "Fuera de Linea"},
        
        {type:"alarm",  text: "Alarma"},
    ]

  
};

export default Settings;
