import { Card, Row, Col } from "react-bootstrap";
import moment from "moment";
import Pagination from "../Common/Pagination";
import React, { useEffect, useState } from "react";
import API from "../../common/API";
import { parsedUser } from "../../common/GetCurrentUser";
import IsLogedIn from "../../common/IsLogedIn";
import AddEditDevice from "./AddEditDevice";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { Routes } from "../../routes";
import { useParams } from "react-router-dom";

//Translation
import { useTranslation } from "react-i18next";
import Preloader from "../Preloader";

const DevicesList = (props) => {
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation("global");
  const [devices, setDevices] = useState({
    records: [],
    totalPages: 0,
    totalRecords: 0,
    currentPage: 0,
    recordsPerPage: 50,
    From: null,
    Until: null,
    UI: {
      isLoading: false,
    },
  });

  const [showAddEdit, setShowAddEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [search, setSearch] = useState("");

  //Device related
  const request = async () => {
    let user = parsedUser();
    console.log(id === undefined);
    console.log(user.id);
    console.log(props.accountId);
    console.log(user);
    let userId = id === undefined ? user.id : props.accountId;
    let query = "id=" + userId + "&search=" + search;
    setDevices({
      ...devices,
      UI: { ...devices, isLoading: true },
    });
    let request = await API.getAction("Device/ForAccount?" + query);
    console.log("Response devices request", request);

    setDevices({
      ...devices,
      UI: { ...devices, isLoading: false },
    });
    if (request.data.status === "ok") {
      setDevices({
        ...devices,
        records: request.data.response,
        totalRecords: request.data.quantityOfRecords,
        totalPages: request.data.quantityOfPages,
      });
    }
  };

  useEffect(() => {
    if (IsLogedIn()) {
      console.log("ENTRO");
      request();
    }
  }, [search]);

  const toggleShowAddEdit = async (obj, reload) => {
    setSelectedItem(obj);
    setShowAddEdit(!showAddEdit);
    if (reload) {
      request();
    }
  };

  const toggleShowDetails = (obj) => {
    history.push(Routes.DeviceDetails.clean + obj.id);
  };

  const onPageChanged = async (obj) => {
    setDevices({ ...devices, currentPage: obj });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const deleteConfirm = async (obj) => {
    let user = parsedUser();

    confirmAlert({
      title: t("generic.words.singular.confirm"),
      message: t("message.warning.sure_delete") + obj.name + "?",
      buttons: [
        {
          label: t("generic.words.singular.yes"),
          onClick: async () => {
            await API.postAction(
              "Device/Delete?userId=" + user.id + "&id=" + obj.id
            );
            await request();
          },
        },
        {
          label: t("generic.words.singular.no"),
        },
      ],
    });
  };
  return (
    <>
      {showAddEdit ? (
        <AddEditDevice
          toggle={toggleShowAddEdit}
          show={showAddEdit}
          model={selectedItem}
        />
      ) : null}

      <Row>
        <Col xs={12}>
          <Card
            className={
              props.accountId === undefined
                ? "shadow-sm m-3 p-2"
                : "shadow-sm rounded-0"
            }
          >
            <Card.Header className="mx-0 p-1">
              <Row>
                <Col className="mt-2 ms-2">
                  <Card.Title>{t("generic.words.plural.devices")}</Card.Title>
                </Col>
                <Col xs={4} className="ml-auto text-right">
                  <div className="input-group">
                    <div className="input-group-text">
                      <i className="fa fa-search"></i>
                    </div>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder={t("generic.words.singular.filter")}
                      value={search}
                      onChange={async (e) => {
                        setSearch(e.target.value);
                      }}
                    />

                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => toggleShowAddEdit()}
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                  </div>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="table-responsive p-1">
              {/* <Preloader show={devices.UI.isLoading} /> */}
              {devices.records.length > 0 ? (
                <table className="table table-stripped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th className="text-center">
                        {t("generic.words.singular.status")}
                      </th>
                      <th>{t("generic.words.singular.name")}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {devices.records.map((device, index) => {
                      let itemParsed = JSON.parse(JSON.stringify(device));
                      return (
                        <tr key={index}>
                          <td>
                            {devices.currentPage === 0
                              ? index + 1
                              : (index + 1) * (devices.currentPage + 1)}
                          </td>
                          <td
                            className={
                              "text-center pt-3 " +
                              (device.status === "online"
                                ? "text-success"
                                : "text-danger")
                            }
                          >
                            {console.log(device)}
                            {device.category === "car" ? (
                              <span className="fa fa-car fa-2x"></span>
                            ) : (
                              <i className="fas fa-map-marker-alt"></i>
                            )}
                          </td>
                          <td
                            className="shadow-sm clickable mb-2"
                            onClick={() => {
                              console.log(device);
                              toggleShowDetails(device);
                            }}
                          >
                            <span>{device.name}</span>
                            <small className="text-muted">
                              &nbsp;{moment(device.lastUpdate).fromNow()}
                            </small>
                            <br />
                            <small className="text-muted">
                              {t("generic.sentences.device_type")}:{" "}
                              {device.deviceType.name ?? "-"}
                            </small>
                          </td>
                          <td className="text-right">
                            <div className="btn-group">
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => {
                                  toggleShowAddEdit(itemParsed);
                                }}
                              >
                                <i className="fa fa-edit" />
                              </button>
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => deleteConfirm(itemParsed)}
                              >
                                <i className="fa fa-trash text-danger" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <p className="text-center my-4">
                  {t("message.info.is_no_content")}
                </p>
              )}
            </Card.Body>
            <Card.Footer>
              <Pagination
                totalPages={devices.totalPages}
                currentPage={devices.currentPage}
                onPageChanged={onPageChanged}
              />
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DevicesList;
