import { Card, Row, Col, Tabs, Container } from "react-bootstrap";
import moment from "moment";
import React, { useEffect, useState } from "react";
import API from "../../common/API";
import Settings from "../../common/Settings";
import PinComponent from "../Common/PinComponent";
import GoogleMapReact from "google-map-react";
import { parsedUser } from "../../common/GetCurrentUser";
import { Tab } from "bootstrap";

//import SMSSender from "./SMSSender";

import { useParams } from "react-router-dom";
import MapHelper from "../../common/MapHelper";
import SubAccountsList from "./SubAccountsList";
import DevicesList from "../Devices/DevicesList";
import { Routes } from "../../routes";
import ChangePassword from "./ChangePassword";

//Translation
import { useTranslation } from "react-i18next";
import { confirmAlert } from "react-confirm-alert";
import { useHistory } from "react-router-dom";
import AddEditUser from "./AddEditUser";

const UserDetails = (props) => {
  const { t } = useTranslation("global");
  const history = useHistory();
  const user = parsedUser();
  const [pins, setPins] = useState([]);
  const params = useParams();
  const [showChangePass, setShowChangePass] = useState(false);
  const GoogleMapRef = React.createRef();

  const [theMap, setTheMap] = useState(null);
  const [theMapsReference, setTheMapsReference] = useState(null);
  const [zoom, setZoom] = useState(18);
  const [state, setState] = useState({
    center: { lat: 18.4718609, lng: -69.8923187 },
    scale: {
      sm: 1,
      md: 2.5,
      lg: 3.5,
      xl: 4.5,
      xxl: 6.5,
    },

    DeviceSelected: null,
    Events: [],
    UI: {
      showAddEditGeofence: false,
      GeofenceSelected: null,
    },
  });

  const [showAddEdit, setShowAddEdit] = useState(false);

  const handleApiLoaded = (map, mapsReference) => {
    setTheMap(map);
    setTheMapsReference(mapsReference);
  };

  const [model, setModel] = useState([]);

  const requestDevices = async () => {
    try {
      let request = await API.getAction("device/positions?userid=" + params.id);

      if (request.data.status === "ok") {
        setPins(request.data.response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //Device related
  const requestUserDetails = async () => {
    try {
      let id = params.id;

      let request = await API.getAction("admin/userdetails?id=" + id);

      console.log(request);

      if (request.data.status === "ok") {
        setModel(request.data.response);

        //setPins([dev.position]);
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  useEffect(() => {
    if (model) {
      MapHelper.centerMap(theMapsReference, theMap, pins, 14);
    }
  }, [theMap, theMapsReference, model, pins]);

  useEffect(() => {
    requestDevices();
    requestUserDetails();
  }, []);

  const deleteConfirm = async () => {
    let user = parsedUser();

    confirmAlert({
      title: t("generic.words.singular.confirm"),
      message:
        t("message.warning.sure_delete") +
        model.firstName +
        " " +
        model.lastName +
        " ?",
      buttons: [
        {
          label: t("generic.words.singular.yes"),
          onClick: async () => {
            /* await API.postAction(
              "Users/Delete?userId=" + user.id + "&id=" + params.id
            ); */

            history.push(Routes.Users.path);
          },
        },
        {
          label: t("generic.words.singular.no"),
        },
      ],
    });
  };
  const selectDevice = (item) => {
    debugger;
    history.push(Routes.DevicesDetails.clean + item.id);

    //  await lastEvents();
  };
  const handleChangePassword = () => {
    setShowChangePass(!showChangePass);
  };
  const onHandleZoom = (obj) => {
    setZoom(obj.zoom);
  };

  const toggle = async (obj, reload) => {
    setShowAddEdit(!showAddEdit);
    if (reload) {
      requestUserDetails();
    }
  };
  if (!model) {
    return <></>;
  }

  return (
    <>
      {showChangePass ? (
        <ChangePassword toggle={handleChangePassword} show={showChangePass} />
      ) : null}

      {showAddEdit ? (
        <AddEditUser toggle={toggle} show={showAddEdit} model={model} />
      ) : null}

      <Container className="mt-2">
        <Row>
          <Col xs={12} lg={4}>
            <Card className="shadow-sm mb-4">
              <Card.Header>
                <h3>
                  {model.firstName} {model.lastName}
                </h3>
              </Card.Header>
              <Card.Body>
                <Col xs={12} className="mb-2">
                  <b>{t("generic.words.singular.email")}: </b>
                  <br />
                  {model.email}
                </Col>
                <Col xs={12} className="mb-2">
                  <b>{t("generic.words.singular.phone")}: </b>
                  <br />
                  {model.phone}
                </Col>
                <Col xs={10} className="mb-2">
                  <b>{t("generic.words.singular.created")}: </b>
                  <br />
                  {/* {model.created && moment(model.created).fromNow()} */}- -
                </Col>
              </Card.Body>
              <Card.Footer>
                <Row>
                  <Col
                    sm={12}
                    md={6}
                    lg={6}
                    xl={4}
                    className="d-flex justify-content-center mt-2"
                  >
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => {
                        toggle();
                      }}
                    >
                      <i className="fas fa-edit"></i>{" "}
                      {t("generic.words.singular.edit")}
                    </button>
                  </Col>

                  <Col
                    sm={12}
                    md={6}
                    lg={6}
                    xl={4}
                    className="d-flex justify-content-center mt-2"
                  >
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => {
                        deleteConfirm();
                      }}
                    >
                      <i className="fa fa-trash text-danger" />{" "}
                      {t("generic.words.singular.delete")}
                    </button>
                  </Col>

                  <Col
                    sm={12}
                    md={12}
                    lg={12}
                    xl={4}
                    className="d-flex justify-content-center mt-2"
                  >
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => handleChangePassword()}
                    >
                      <i className="fas fa-key"></i>{" "}
                      {t("generic.sentences.edit_password")}
                    </button>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
          <Col xs={12} lg={8}>
            <Card className="shadow-sm mb-4">
              <Card.Body>
                {console.log(model, pins)}
                <GoogleMapReact
                  ref={GoogleMapRef}
                  yesIWantToUseGoogleMapApiInternals={true}
                  onGoogleApiLoaded={({ map, maps }) =>
                    handleApiLoaded(map, maps)
                  }
                  bootstrapURLKeys={{ key: Settings.GoogleMapsAPIKey }}
                  style={{
                    width: "100%",
                    minHeight: "300px",
                    height: "68vh",
                    position: "relative",
                  }}
                  center={{ lat: state.center.lat, lng: state.center.lng }}
                  onChange={(e) => onHandleZoom(e)}
                  defaultZoom={17}
                >
                  {/* {model ? (
                        <PinComponent
                          model={model}
                          lat={model.position.latitude}
                          lng={model.position.longitude}
                          title={model.device.name}
                        />
                      ) : null} */}
                  {pins
                    .filter((x) => x.positionInfo)
                    .map((item, index) => {
                      console.log(item);
                      return (
                        <PinComponent
                          model={item}
                          key={index}
                          lat={item.positionInfo.latitude}
                          lng={item.positionInfo.longitude}
                          zoom={zoom}
                          title={item.name}
                          scale={state.scale}
                          onClick={selectDevice}
                        />
                      );
                    })}
                </GoogleMapReact>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12}>
            <Card className="shadow-sm mb-4">
              <Card.Body>
                <Tabs
                  defaultActiveKey="devices"
                  id="justify-tab-example"
                  className="containerTab"
                  justify
                  fill
                >
                  <Tab
                    eventKey="devices"
                    title={t("generic.words.plural.devices")}
                  >
                    <div>
                      <DevicesList accountId={params.id} />
                    </div>
                  </Tab>
                  <Tab
                    eventKey="subAccounts"
                    title={t("generic.words.plural.subaccounts")}
                  >
                    <SubAccountsList id={params.id} />
                  </Tab>
                </Tabs>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UserDetails;
