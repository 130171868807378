import React from "react";
import { useHistory } from "react-router-dom";
import { Routes } from "../../routes";
import autoSizePin from "../../common/AutoSizePinMap";

const PinComponent = ({ model, zoom, geofence, scale }) => {
  const history = useHistory();
  const toggleShowDetails = (obj) => {
    if (obj && model.type === "stops") {
      return;
    }
    history.push(Routes.DeviceDetails.clean + obj.id);
  };
  const validatedStatus = () => {
    let status = false;
    if (Object.keys(model.attributes).length !== 0) {
      status = model.attributes.status === 0;
    } else if (Object.keys(model.positionInfo.attributes).length !== 0) {
      status = model.positionInfo.attributes.status === 0;
    }
    console.log(status);
    return status;
  };

  const validatedSpeed = (speed) => {
    let status = false;
    if (speed) {
      status = model.positionInfo?.speed > 0 || model.speed > 0;
    } else {
      status = model.positionInfo?.speed === 0 || model.speed === 0;
    }

    return status;
  };

  const evaluateStatus = () => {
    if (validatedStatus() && validatedSpeed(false)) {
      return "blue";
    } else if (validatedStatus() && validatedSpeed(true)) {
      return "green";
    } else {
      return "#ff0000";
    }
  };

  const getCarHandPaper = () => {
    //model.type === "stops"
    if (validatedStatus() && !validatedSpeed(true)) {
      return (
        <>
          <i
            style={{
              fontSize: autoSizePin(scale, zoom) / 2 + "px",

              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-55%, -75%)",
            }}
            className="fas fa-hand-paper text-light"
          />
          <br />
          {/* {model.lenght.index + 1} */}
        </>
      );
    } else {
      return (
        <i
          style={{
            fontSize: autoSizePin(scale, zoom) / 2 + "px",

            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -75%)",
          }}
          className="fa fa-car text-light "
        />
      );
    }
  };

  return (
    <>
      <div
        className={"marker"}
        onClick={() => toggleShowDetails(model)}
        title={model?.name}
        style={{
          width: autoSizePin(scale, zoom) + "px",

          top: "50%",
          left: "50%",
          transform: "translate(-50%, -100%)",
          position: "relative",
        }}
      >
        <svg
          baseProfile="basic"
          xmlns="http://www.w3.org/2000/svg"
          fill={evaluateStatus()}
          viewBox="0 0 48 48"
        >
          <path d="M24 0c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z"></path>
        </svg>
        {!geofence ? getCarHandPaper() : null}
      </div>
    </>
  );
};

export default PinComponent;
