import React from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { ProfileCardWidget, ChoosePhotoWidget } from "../Widgets";
import Profile3 from "../../assets/img/team/profile-picture-3.jpg";

//Translation
import { useTranslation } from "react-i18next";
import { languages } from "../../common/languages";

const Profile = () => {
  const { t, i18n } = useTranslation("global");

  const handlerSelectedLanguage = (e) => {
    localStorage.setItem("language", e.target.value);
    i18n.changeLanguage(e.target.value);
  };

  return (
    <>
      <Col xs={12} xl={4}>
        <Row>
          <Col xs={12}>
            <ProfileCardWidget />
          </Col>

          <Col xs={12}>
            <ChoosePhotoWidget
              title={t("generic.sentences.select_profile_photo")}
              photo={Profile3}
            />
          </Col>

          <Col xs={12}>
            <Card>
              <Card.Header>
                <Card.Title>
                  {t("generic.words.singular.preference")}
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col xs={12}>
                    <b>{t("generic.words.plural.languages")}</b>
                    <Form.Select
                      defaultValue={i18n.language}
                      onChange={handlerSelectedLanguage}
                    >
                      <option value="" disabled>
                        {t("generic.sentences.select_language")}...
                      </option>
                      {languages.map((language) => (
                        <option key={language.code} value={language.code}>
                          {language.name}
                        </option>
                      ))}
                    </Form.Select>
                    <hr />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Profile;
