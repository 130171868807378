import axios from "axios";
import Settings from "./Settings";
import GetCurrentUser, { parsedUser } from "./GetCurrentUser";

let options = {
  headers: { "Content-Type": "application/json", accept: "text/plain" },

  crossdomain: true,
};
axios.interceptors.request.use((config) => {
  // log a message before any HTTP request is sent
  console.log("The request ==== ", config);
  return config;
});
export default {
  postAction: async (endpoint, params, newOpts) => {
    let user = GetCurrentUser();
    if (user) {
      options = {
        headers: {
          "Content-Type": "application/json",
          accept: "text/plain",
          Authorization: "Bearer " + user,
        },

        crossdomain: true,
      };
    }
    return axios.post(
      Settings.BasePathForAPI + endpoint,
      params !== null ? params : null,
      newOpts !== null ? newOpts : options
    );
  },
  getAction: async (endpoint, params, newOpts) => {
    let user = GetCurrentUser();
    if (user) {
      options = {
        headers: {
          "Content-Type": "application/json",
          accept: "text/plain",
          Authorization: "Bearer " + user,
        },

        crossdomain: true,
      };
    }
    return axios.get(
      Settings.BasePathForAPI +
        endpoint +
        (params !== undefined && params !== null ? "?" + params : ""),
      newOpts !== undefined && newOpts !== null ? newOpts : options
    );
  },
  postActionExternal: async (endpoint, params, newOpts) =>
    axios.post(
      endpoint,
      params !== null ? params : null,
      newOpts !== null ? newOpts : options
    ),
  getActionExternal: async (endpoint, newOpts) => {
    let results = axios.get(endpoint, newOpts);

    return results;
  },
};
