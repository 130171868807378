import { Card, Row, Col, Container } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import API from "../../common/API";
import { Routes } from "../../routes";
import { useParams } from "react-router-dom";

//Translation
import { useTranslation } from "react-i18next";
import CommandMaintenanceList from "../CommandMaintenance/CommandMaintenanceList";
import { confirmAlert } from "react-confirm-alert";
import { parsedUser } from "../../common/GetCurrentUser";
import { useHistory } from "react-router-dom";
import AddEditDeviceType from "./AddEditDeviceType";

const DeviceTypeDetails = (props) => {
  const { t } = useTranslation("global");
  const { id } = useParams();
  const history = useHistory();

  const [device, setDevice] = useState({});
  const [showAddEdit, setShowAddEdit] = useState(false);

  //Device related
  const requestDevice = async () => {
    try {
      let request = await API.getAction("DeviceType/Details?id=" + id);

      if (request.data.status === "ok") {
        let dev = request.data.response;

        setDevice(dev);

        console.log(dev.name);
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  useEffect(() => {
    requestDevice();
  }, [id]);

  const deleteConfirm = async () => {
    let user = parsedUser();

    confirmAlert({
      title: t("generic.words.singular.confirm"),
      message: t("message.warning.sure_delete") + device.name + "?",
      buttons: [
        {
          label: t("generic.words.singular.yes"),
          onClick: async () => {
            await API.postAction(
              "DeviceType/Delete?userId=" + user.id + "&id=" + id
            );

            history.push(Routes.DeviceType.path);
          },
        },
        {
          label: t("generic.words.singular.no"),
        },
      ],
    });
  };

  const toggle = async (obj, reload) => {
    setShowAddEdit(!showAddEdit);

    if (reload) {
      console.log("RELOAD", reload);
      await requestDevice();
    }
  };

  return (
    <>
      {showAddEdit ? (
        <AddEditDeviceType toggle={toggle} show={showAddEdit} model={device} />
      ) : null}
      <Container>
        <nav aria-label="breadcrumb" className="mb-2">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">
                <i className="fa fa-home"></i>
              </a>
            </li>

            <li className="breadcrumb-item">
              <a href={Routes.DeviceType.path}>
                {t("generic.sentences.device_type")}
              </a>
            </li>
            <li className="breadcrumb-item">
              {t("generic.words.plural.details")}
            </li>
          </ol>
        </nav>
        <Row>
          <Col sm={12} lg={6} xl={4}>
            <Card className="shadow-sm mb-4">
              <Card.Header>
                <Card.Title>
                  <h3>{t("generic.sentences.device_type_details")}</h3>
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col xs={12} className="mb-2">
                    <b>{t("generic.words.singular.name")}: </b>
                    {device.name}
                  </Col>
                  <Col xs={12} className="mb-2">
                    <b>{t("generic.words.singular.reference")}: </b>
                    {device.reference}
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <Row>
                  <Col xs={6} className="d-flex justify-content-center">
                    <button
                      className="btn btn-primary btn-sm "
                      onClick={() => {
                        toggle();
                      }}
                    >
                      <i className="fa fa-edit" />{" "}
                      {t("generic.words.singular.edit")}
                    </button>
                  </Col>
                  <Col xs={6} className="d-flex justify-content-center">
                    <button
                      className="btn btn-primary btn-sm "
                      onClick={() => {
                        deleteConfirm();
                      }}
                    >
                      <i className="fa fa-trash text-danger" />{" "}
                      {t("generic.words.singular.delete")}
                    </button>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </Col>
          <Col>
            <CommandMaintenanceList deviceTypeId={id} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DeviceTypeDetails;
