import React, { useState } from "react";

import { Modal, Button, Col, Row, Form } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import API from "../../common/API";
import { parsedUser } from "../../common/GetCurrentUser";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

//Translation
import { useTranslation } from "react-i18next";

//Component
const AddEditDevice = (props) => {
  const { t } = useTranslation("global");
  const { id } = useParams();
  const [state, setState] = useState({
    deviceType: [],
    UI: {
      isLoading: false,
    },
  });

  useEffect(() => {
    request();
  }, []);

  //Validation
  const validationSchema = yup.object({
    name: yup.string().required(t("message.warning.field_required")),
    deviceTypeId: yup.string().required(t("message.warning.field_required")),
    identifier: yup.string().required(t("message.warning.field_required")),
    phone: yup.string().required(t("message.warning.field_required")),
  });

  const request = async () => {
    try {
      let requestData = await API.getAction("DeviceType/List");

      if (requestData.data.status === "ok") {
        setState({ ...state, deviceType: requestData.data.response });
      }
    } catch (error) {
      console.error("THATS A ERROR!!!", error);
    }
  };

  const handleSubmit = async (obj) => {
    try {
      let path = "";
      let user = parsedUser();

      if (obj.id.length === 0) {
        let userId = id ? id : user.id;

        path = "Device/Add?userId=" + userId;
      } else {
        path = "Device/Update?userId=" + obj.accountId + "&id=" + obj.id;
      }

      let params = {
        name: obj.name,
        deviceTypeId: obj.deviceTypeId,
        identifier: obj.identifier,
        phone: obj.phone,
      };

      setState({
        ...state,
        UI: { ...state, isLoading: true },
      });

      let request = await API.postAction(path, params);

      setState({
        ...state,
        UI: { ...state, isLoading: false },
      });

      if (request.status === 200) {
        console.log("Request Data 200", request.data);
        props.toggle("null", true);
      }
    } catch (ex) {
      console.error(ex);
      setState({
        ...state,
        UI: { ...state, isLoading: false },
      });
    }
  };

  const formik = useFormik({
    initialValues: props.model
      ? JSON.parse(JSON.stringify(props.model))
      : {
          name: "",
          deviceTypeId: "",
          identifier: "",
          phone: "",
          id: "",
        },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Modal show={props.show} onHide={props.toggle} size="md">
      <Modal.Header closeButton>
        <Modal.Title>{t("generic.words.singular.device")}</Modal.Title>
      </Modal.Header>

      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Row>
            <Col xs={6}>
              <Form.Label>{t("generic.words.singular.name")}</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />

              {formik.errors.name ? (
                <div className="invalid text-sm">{formik.errors.name}</div>
              ) : null}
            </Col>
            <Col xs={6}>
              <Form.Label>{t("generic.sentences.device_type")}</Form.Label>
              <Form.Select
                name="deviceTypeId"
                value={
                  formik.values.deviceTypeId
                    ? formik.values.deviceTypeId
                    : "DEFAULT"
                }
                onChange={formik.handleChange}
              >
                <option value="DEFAULT" disabled>
                  {t("generic.sentences.select_device_type")}
                </option>
                {state.deviceType.map((type, index) => {
                  return (
                    <option key={index} value={type.id}>
                      {type.name}
                    </option>
                  );
                })}
              </Form.Select>
              {formik.errors.deviceType ? (
                <div className="invalid text-sm">
                  {formik.errors.deviceType}
                </div>
              ) : null}
            </Col>

            <Col xs={6}>
              <Form.Label>{t("generic.words.singular.identifier")}</Form.Label>
              <Form.Control
                type="text"
                name="identifier"
                value={formik.values.identifier}
                onChange={formik.handleChange}
              />
              {formik.errors.identifier ? (
                <div className="invalid text-sm">
                  {formik.errors.identifier}
                </div>
              ) : null}
            </Col>
            <Col xs={6}>
              <Form.Label>{t("generic.words.singular.phone")}</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
              />
              {formik.errors.phone ? (
                <div className="invalid text-sm">{formik.errors.phone}</div>
              ) : null}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            type="submit"
            className="btn-sm"
            disabled={state.UI.isLoading}
          >
            {t("generic.words.singular.save")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddEditDevice;
