import React, { useState } from "react";

import { Modal, Button, Col, Row, Form } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import API from "../../common/API";
import { parsedUser } from "../../common/GetCurrentUser";

//Translation
import { useTranslation } from "react-i18next";

const AddEditDeviceType = (props) => {
  const { t } = useTranslation("global");
  const [state, setState] = useState({
    UI: {
      isLoading: false,
    },
  });

  //Validation
  const validationSchema = yup.object({
    name: yup.string().required(t("message.warning.field_required")),
    reference: yup.string().required(t("message.warning.field_required")),
  });

  const handleSubmit = async (obj) => {
    try {
      let path = "";
      let user = parsedUser();

      if (obj.id.length === 0) {
        path = "DeviceType/Add?userId=" + user.id;
      } else {
        path = "deviceType/Update?userId=" + user.id + "&id=" + obj.id;
      }

      console.log(obj);
      console.log("PATH", path);

      let otherData = {
        name: obj.name,
        reference: obj.reference,
      };

      setState({
        ...state,
        UI: { ...state, isLoading: true },
      });

      let request = await API.postAction(path, otherData);

      setState({
        ...state,
        UI: { ...state, isLoading: false },
      });

      if (request.status === 200) {
        console.log("Request Data 200", request.data);
        props.toggle(null, true);
      }
    } catch (ex) {
      console.error(ex);
      setState({
        ...state,
        UI: { ...state.UI, isLoading: false },
      });
    }
  };

  const formik = useFormik({
    initialValues: props.model
      ? JSON.parse(JSON.stringify(props.model))
      : {
          name: "",
          reference: "",
          id: "",
        },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Modal show={props.show} onHide={props.toggle} size="md">
      <Modal.Header closeButton>
        <Modal.Title>{t("generic.sentences.device_type")}</Modal.Title>
      </Modal.Header>

      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <Form.Label>{t("generic.words.singular.name")}</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />

              {formik.errors.name ? (
                <div className="invalid text-sm">{formik.errors.name}</div>
              ) : null}
            </Col>
            <Col xs={12}>
              <Form.Label>{t("generic.words.singular.reference")}</Form.Label>
              <Form.Control
                type="text"
                name="reference"
                value={formik.values.reference}
                onChange={formik.handleChange}
              />
              {formik.errors.reference ? (
                <div className="invalid text-sm">{formik.errors.reference}</div>
              ) : null}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            type="submit"
            className="btn-sm"
            disabled={state.UI.isLoading}
          >
            {t("generic.words.singular.save")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddEditDeviceType;
