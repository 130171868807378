import { Card, Row, Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import API from "../../common/API";
import moment from "moment";
import Pagination from "../Common/Pagination";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { parsedUser } from "../../common/GetCurrentUser";
import { useHistory } from "react-router-dom";

//translation
import { useTranslation } from "react-i18next";
import { Routes } from "../../routes";

const SubscriptionsList = () => {
  const { t } = useTranslation("global");
  const history = useHistory();
  const [state, setState] = useState({
    records: [],
    totalPages: 0,
    totalRecords: 0,
    currentPage: 0,
    recordsPerPage: 50,
    From: null,
    Until: null,
  });

  useEffect(() => {
    request();
  }, []);

  const request = async () => {
    let query = "";
    query += "Page=" + state.currentPage;
    query += "&Quantity=" + state.recordsPerPage;
    if (state.From !== null) {
      query += "&From=" + moment(state.From).unix();
    }
    if (state.Until !== null) {
      query += "&To=" + moment(state.Until).unix();
    }

    var request = ""; /* await API.getAction("DeviceType/List", query) */
    /* 
    if (request.data.status === "ok") {
      setState({
        ...state,
        records: request.data.response,
        totalRecords: request.data.quantityOfRecords,
        totalPages: request.data.quantityOfPages,
      });
    } */
  };

  const onPageChanged = async (obj) => {
    setState({ ...state, currentPage: obj });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const [showAddEdit, setShowAddEdit] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const toggle = async (obj, reload) => {
    setSelectedItem(obj);
    setShowAddEdit(!showAddEdit);

    if (reload) {
      console.log("RELOAD", reload);
      await request();
    }
  };
  const deleteConfirm = async (obj) => {
    let user = parsedUser();

    console.log("USER", user.id);
    console.log("OBJ", obj.id);
    confirmAlert({
      title: t("generic.words.singular.confirm"),
      message: t("message.warning.sure_delete") + obj.name + "?",
      buttons: [
        {
          label: t("generic.words.singular.yes"),
          onClick: async () => {
            await API.postAction(
              "DeviceType/Delete?userId=" + user.id + "&id=" + obj.id
            );
            await request();
          },
        },
        {
          label: t("generic.words.singular.no"),
        },
      ],
    });
  };
  return (
    <>
      <Row>
        <Col xs={12}>
          <Card className="shadow-sm m-3 p-2">
            <Card.Header className="row mx-0 p-1">
              <div className="col">
                <Card.Title>
                  {t("generic.words.plural.subscriptions")}
                </Card.Title>
              </div>
              <div className="col-4 text-right">
                <button
                  className="btn btn-sm btn-primary"
                  onClick={() => toggle()}
                >
                  <i className="fa fa-plus"></i>
                </button>
              </div>
            </Card.Header>
            <Card.Body className="table-responsive p-1">
              {state.records.length === 0 ? (
                <table className="table table-stripped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{t("generic.words.singular.name")}</th>
                      <th>{t("generic.words.singular.description")}</th>
                      <th>{t("generic.words.singular.price")}</th>

                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.records.map((item, index) => {
                      let itemParsed = JSON.parse(JSON.stringify(item));
                      return (
                        <tr key={index}>
                          <td>
                            {state.currentPage === 0
                              ? index + 1
                              : (index + 1) * (state.currentPage + 1)}
                          </td>
                          <td
                            className="shadow-sm clickable mb-2"
                            onClick={() => {
                              console.log("AQUI", item);
                              history.push(
                                Routes.DeviceTypeDetails.clean + item.id
                              );
                            }}
                          >
                            {item.name}
                          </td>
                          <td>{item.reference}</td>
                          <td className="text-right">
                            <div className="btn-group">
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => toggle(itemParsed)}
                              >
                                <i className="fa fa-edit" />
                              </button>
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => deleteConfirm(itemParsed)}
                              >
                                <i className="fa fa-trash text-danger" />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              ) : (
                <p className="text-center my-4">
                  {t("message.info.is_no_content")}
                </p>
              )}
            </Card.Body>
            <Card.Footer>
              <Pagination
                totalPages={state.totalPages}
                currentPage={state.currentPage}
                onPageChanged={onPageChanged}
              />
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SubscriptionsList;
