import React, { useState } from "react";

import { Modal, Button, Col, Row, Form } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import API from "../../common/API";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import Sha256 from "../../common/sha256";

//Translation
import { useTranslation } from "react-i18next";

//Component
const AddEditUser = (props) => {
  const { t } = useTranslation("global");

  const validationSchema = yup.object({
    firstName: yup.string().required(t("message.warning.field_required")),
    lastName: yup.string().required(t("message.warning.field_required")),
    email: yup.string().required(t("message.warning.field_required")),
    phone: yup.string().required(t("message.warning.field_required")),
    password: !props.model
      ? yup
          .string()
          .required(t("message.warning.field_required"))
          .min(9, t("message.warning.min_password"))
      : yup.string(),
    confirmPassword: !props.model
      ? yup
          .string()
          .required(t("message.warning.field_required"))
          .oneOf(
            [yup.ref("password"), null],
            t("message.warning.password_must_match")
          )
          .min(9, t("message.warning.min_password"))
      : yup.string(),
  });

  const [state, setState] = useState({
    UI: {
      isLoading: false,
    },
  });

  const handleSubmit = async (obj) => {
    try {
      console.log("values to submit", obj);
      let path = "Account/Add";

      if (obj.id.length === 0) {
        obj.id = uuidv4();
        obj.created = moment().format("YYYY-MM-DDTHH:mm:ss");
      } else {
        path = "Account/Update";
      }

      console.log(obj);
      let dataAdd = {
        id: obj.id,
        firstName: obj.firstName,
        lastName: obj.lastName,
        email: obj.email,
        phone: obj.phone,
        password: obj.password ? Sha256.hash(obj.password) : null,
        pic: null,
        parentAccountId: null,
      };
      console.log(obj.password);
      console.log(dataAdd);
      setState({
        ...state,
        UI: { ...state, isLoading: true },
      });

      let request = await API.postAction(path, dataAdd);

      setState({
        ...state,
        UI: { ...state, isLoading: false },
      });

      if (request.status === 200) {
        console.log("Request Data 200", request.data);
        props.toggle(null, true);
      }
    } catch (ex) {
      console.error(ex);
      setState({
        ...state,
        UI: { ...state, isLoading: false },
      });
    }
  };

  const formik = useFormik({
    initialValues: props.model
      ? JSON.parse(JSON.stringify(props.model))
      : {
          firstName: "",
          lastName: "",
          parentAccountId: null,
          pic: null,
          phone: "",
          password: "",
          confirmPassword: "",
          email: "",
          id: "",
        },
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <Modal show={props.show} onHide={props.toggle} size="md">
      <Modal.Header closeButton>
        <Modal.Title>{t("generic.words.singular.user")}</Modal.Title>
      </Modal.Header>

      <Form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Row>
            <Col xs={6}>
              <Form.Label>{t("generic.words.singular.firstname")}</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
              />

              {formik.errors.firstName ? (
                <div className="invalid text-sm">{formik.errors.firstName}</div>
              ) : null}
            </Col>
            <Col xs={6}>
              <Form.Label>{t("generic.words.singular.lastname")}</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
              />
              {formik.errors.lastName ? (
                <div className="invalid text-sm">{formik.errors.lastName}</div>
              ) : null}
            </Col>

            <Col xs={6}>
              <Form.Label>{t("generic.words.singular.email")}</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              {formik.errors.email ? (
                <div className="invalid text-sm">{formik.errors.email}</div>
              ) : null}
            </Col>
            <Col xs={6}>
              <Form.Label>{t("generic.words.singular.phone")}</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
              />
              {formik.errors.phone ? (
                <div className="invalid text-sm">{formik.errors.phone}</div>
              ) : null}
            </Col>
            {!props.model ? (
              <>
                <Col xs={12}>
                  <Form.Label>
                    {t("generic.words.singular.password")}
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.password ? (
                    <div className="invalid text-sm">
                      {formik.errors.password}
                    </div>
                  ) : null}
                </Col>
                <Col xs={12}>
                  <Form.Label>
                    {t("generic.sentences.confirm_password")}
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="confirmPassword"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.confirmPassword ? (
                    <div className="invalid text-sm">
                      {formik.errors.confirmPassword}
                    </div>
                  ) : null}
                </Col>
              </>
            ) : null}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            type="submit"
            className="btn-sm"
            disabled={state.UI.isLoading}
          >
            {t("generic.words.singular.save")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddEditUser;
